import React from 'react';

import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  adaptiveHeight: true,

}

const Carousel1 = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {sourceInstanceName: {eq: "multiple"}}) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64

              }
            }
          }
        }
      }
    }
  `)
  const images = data.allFile.edges
  return (

    <Container>
      <Slider {...settings} className="overflow-hidden">
        {images.map((image, index) => {
          return <Img className="imagination"
            objectFit="contain" fluid={image.node.childImageSharp.fluid} />
        })} </Slider>

    </Container>
  )
}

export default Carousel1

const Container = styled.div`
position: relative;
  max-width: 800px;
  margin: auto;

  .slick-prev {
    left: 0px;
    z-index:10;
    
    width:50px; 
    &:before{
      font-size:45px;
      color:black;
    }
}
.slick-next {
    right: 0px;
    width:50px;
 &:before{
      font-size:45px;
      color:black;
      left: 0px;

    }
    }
` 