import React from "react"
import styles from "../css/postTemplate.module.css"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Carousel1 from '../components/Projects/carousel/carousel1'

export const query = graphql`
 {
  file(relativePath: {eq: "MultipleLogo.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}

`

const Multiple = ({ data }) => {
    const author = 'Yukuri'
    const title = 'Multiple'
    return (
        <Layout>
            <section className={styles.template}>
                <Link to="/projects" className={styles.link}>
                    {" "}
            back to projects
          </Link>
                <div className={styles.info}>
                    <h1>{title}</h1>
                    <h4>
                        <span>by {author}</span>
                    </h4>
                </div>
                <Image fluid={data.file.childImageSharp.fluid} />          <div className={styles.content}>
                    <div>Realicé la creación del branding de la marca Múltiple. Una marca de calzoncillos para el hombre
  actual. La esencia de la marca es la de una marca joven y fresca, orientada a un público joven y joven
  adulto.
  
  Al ser una marca que debía captar la atención tanto de adolescentes como de adultos busque
  una estética para el branding elegante siguiendo las tendencias de diseño actuales entre los
  jóvenes.
  La mezcla resultó en un logo con influencia Art Deco y minimalista.
  
  El proyecto consistió en la creación del branding, un manual corporativo básico, un Mupi, polidiptico
  y tres patrones para los calzoncillos.</div>

                </div>                    <Carousel1 />

            </section>
        </Layout>
    )
}

export default Multiple
